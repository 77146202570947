var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LoadTitle } from './state/title-actions';
import { Store } from '@ngxs/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var TitleResolverService = /** @class */ (function () {
    function TitleResolverService(store) {
        this.store = store;
    }
    TitleResolverService.prototype.resolve = function (route) {
        var params = __assign({}, route.params);
        if (route.data.fullCredits)
            params.fullCredits = true;
        return this.store.dispatch(new LoadTitle(params.titleId, params));
    };
    TitleResolverService.ngInjectableDef = i0.defineInjectable({ factory: function TitleResolverService_Factory() { return new TitleResolverService(i0.inject(i1.Store)); }, token: TitleResolverService, providedIn: "root" });
    return TitleResolverService;
}());
export { TitleResolverService };
