<figure class="item" *ngFor="let item of items">
    <media-image [src]="item.poster" size="medium" [link]="urls.mediaItem(item)">
        <button mat-fab color="warn" *ngIf="hasListeners() || !isPerson(item)" (click)="executeAction(item)">
            <mat-icon [svgIcon]="actionIcon"></mat-icon>
        </button>
    </media-image>
    <figcaption>
        <rating-widget [item]="item" *ngIf="!isPerson(item) && item.rating" class="small"></rating-widget>
        <a class="title" [routerLink]="urls.mediaItem(item)">{{item.name}}</a>
        <genre-widget [genres]="item.genres" [limit]="3" *ngIf="item.type !== 'person'"></genre-widget>
    </figcaption>
</figure>