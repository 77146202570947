var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State, Store } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadFilterOptions, LoadMoreTitles, ReloadTitles, UpdateFilters } from './browse-title-actions';
import { TitlesService } from '../../titles.service';
import { ValueLists } from '../../../../../common/core/services/value-lists.service';
import { TITLE_GENRE_OPTIONS } from '../../components/browse-titles/select-options/title-genre-options';
import { TITLE_CERTIFICATION_OPTIONS } from '../../components/browse-titles/select-options/title-certification-options';
import { Navigate } from '@ngxs/router-plugin';
var BrowseTitleState = /** @class */ (function () {
    function BrowseTitleState(store, titles, valueLists) {
        this.store = store;
        this.titles = titles;
        this.valueLists = valueLists;
    }
    BrowseTitleState.titles = function (state) {
        return state.titles.data;
    };
    BrowseTitleState.loading = function (state) {
        return state.loading;
    };
    BrowseTitleState.canLoadMore = function (state) {
        return state.titles.current_page < state.titles.last_page;
    };
    BrowseTitleState.doesNotHaveResults = function (state) {
        // loaded titles from backend at least once
        return state.titles.data && !state.titles.data.length;
    };
    BrowseTitleState.anyFilterActive = function (state) {
        return Object.keys(state.filters).length > 0;
    };
    BrowseTitleState.countries = function (state) {
        return state.filterOptions.countries;
    };
    BrowseTitleState.languages = function (state) {
        return state.filterOptions.languages;
    };
    BrowseTitleState.genres = function (state) {
        return state.filterOptions.genres;
    };
    BrowseTitleState.certifications = function (state) {
        return state.filterOptions.certifications;
    };
    BrowseTitleState.filters = function (state) {
        return state.filters;
    };
    BrowseTitleState.prototype.reloadTitles = function (ctx, action) {
        var newFilters = this.queryParamsToFilters(action.params);
        ctx.patchState({
            loading: true,
            filters: newFilters,
        });
        // apply specified filters as query params to current url
        this.store.dispatch(new Navigate([], this.filtersToQueryParams(newFilters)));
        return this.titles.getAll(action.params).pipe(tap(function (response) { return ctx.patchState({ titles: response }); }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    BrowseTitleState.prototype.loadMoreTitles = function (ctx) {
        ctx.patchState({ loading: true });
        var filters = __assign({}, ctx.getState().filters, { page: ctx.getState().titles.current_page + 1 });
        return this.titles.getAll(filters).pipe(tap(function (response) {
            var oldData = ctx.getState().titles.data.slice();
            response.data = oldData.concat(response.data);
            ctx.patchState({ titles: response });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    BrowseTitleState.prototype.updateFilters = function (ctx, action) {
        ctx.patchState({
            filters: this.queryParamsToFilters(action.filters),
        });
    };
    BrowseTitleState.prototype.loadFilterOptions = function (ctx) {
        this.valueLists.get(['countries', 'languages']).subscribe(function (response) {
            ctx.patchState({
                filterOptions: __assign({}, ctx.getState().filterOptions, { languages: response.languages, countries: response.countries })
            });
        });
    };
    BrowseTitleState.prototype.queryParamsToFilters = function (params) {
        var formValues = {};
        var keys = ['genre', 'certification'];
        Object.keys(params).forEach(function (key) {
            if (!params[key])
                return;
            formValues[key] = keys.indexOf(key) > -1 && !Array.isArray(params[key]) ?
                params[key].split(',') :
                params[key];
        });
        return formValues;
    };
    BrowseTitleState.prototype.filtersToQueryParams = function (values) {
        var queryParams = {};
        Object.keys(values).forEach(function (key) {
            if (!values[key])
                return;
            queryParams[key] = Array.isArray(values[key]) ?
                values[key].join(',') :
                values[key];
        });
        return queryParams;
    };
    __decorate([
        Action(ReloadTitles),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ReloadTitles]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "reloadTitles", null);
    __decorate([
        Action(LoadMoreTitles),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "loadMoreTitles", null);
    __decorate([
        Action(UpdateFilters),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateFilters]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "updateFilters", null);
    __decorate([
        Action(LoadFilterOptions),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "loadFilterOptions", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "titles", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "loading", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "canLoadMore", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "doesNotHaveResults", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "anyFilterActive", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "countries", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "languages", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "genres", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "certifications", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], BrowseTitleState, "filters", null);
    BrowseTitleState = __decorate([
        State({
            name: 'browseTitles',
            defaults: {
                loading: false,
                filters: {},
                filterOptions: {
                    genres: TITLE_GENRE_OPTIONS,
                    certifications: TITLE_CERTIFICATION_OPTIONS,
                    countries: [],
                    languages: [],
                }
            }
        }),
        __metadata("design:paramtypes", [Store,
            TitlesService,
            ValueLists])
    ], BrowseTitleState);
    return BrowseTitleState;
}());
export { BrowseTitleState };
