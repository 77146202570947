var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadRelatedVideos, PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from './player-state-actions';
import { TitlesService } from '../../titles/titles.service';
var PlayerState = /** @class */ (function () {
    function PlayerState(titles) {
        this.titles = titles;
    }
    PlayerState.isOpen = function (state) {
        return state.isOpen;
    };
    PlayerState.activeVideo = function (state) {
        return state.activeVideo;
    };
    PlayerState.relatedVideos = function (state) {
        return state.relatedVideos;
    };
    PlayerState.mediaItem = function (state) {
        return state.mediaItem;
    };
    PlayerState.sidebarOpen = function (state) {
        return state.sidebarOpen;
    };
    PlayerState.prototype.playVideo = function (ctx, action) {
        var state = ctx.getState();
        // already have this video and title loaded
        if (state.activeVideo && state.activeVideo.id === action.video.id) {
            return;
        }
        ctx.patchState({ activeVideo: action.video, mediaItem: action.mediaItem });
        ctx.dispatch(new LoadRelatedVideos());
    };
    PlayerState.prototype.loadRelatedVideos = function (ctx) {
        var mediaItem = ctx.getState().mediaItem;
        return this.titles.getRelatedVideos(mediaItem['title_id'] || mediaItem.id, ctx.getState().activeVideo.id).pipe(tap(function (response) {
            ctx.patchState({ relatedVideos: response.videos });
        }));
    };
    PlayerState.prototype.playerOverlayClosed = function (ctx) {
        return ctx.patchState({ isOpen: false });
    };
    PlayerState.prototype.playerOverlayOpened = function (ctx) {
        return ctx.patchState({ isOpen: true });
    };
    PlayerState.prototype.toggleSidebar = function (ctx, action) {
        var state = action.open === null ? !ctx.getState().sidebarOpen : action.open;
        return ctx.patchState({ sidebarOpen: state });
    };
    __decorate([
        Action(PlayVideo),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, PlayVideo]),
        __metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playVideo", null);
    __decorate([
        Action(LoadRelatedVideos),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState.prototype, "loadRelatedVideos", null);
    __decorate([
        Action(PlayerOverlayClosed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playerOverlayClosed", null);
    __decorate([
        Action(PlayerOverlayOpened),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playerOverlayOpened", null);
    __decorate([
        Action(ToggleSidebar),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ToggleSidebar]),
        __metadata("design:returntype", void 0)
    ], PlayerState.prototype, "toggleSidebar", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState, "isOpen", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState, "activeVideo", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState, "relatedVideos", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState, "mediaItem", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PlayerState, "sidebarOpen", null);
    PlayerState = __decorate([
        State({
            name: 'player',
            defaults: {
                relatedVideos: [],
                isOpen: false,
                sidebarOpen: true,
            }
        }),
        __metadata("design:paramtypes", [TitlesService])
    ], PlayerState);
    return PlayerState;
}());
export { PlayerState };
