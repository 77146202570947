import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {LoadTitle} from './state/title-actions';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TitleResolverService implements Resolve<void> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot): Observable<void> {
        const params = {...route.params};
        if (route.data.fullCredits) params.fullCredits = true;
        return this.store.dispatch(new LoadTitle(params.titleId, params));
    }
}
