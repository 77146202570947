var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BehaviorSubject, Subject } from 'rxjs';
import { randomString } from '../../core/utils/random-string';
var UploadQueueItem = /** @class */ (function () {
    function UploadQueueItem(file) {
        this.id = randomString();
        this.canceled$ = new Subject();
        // meta information (will change)
        this.meta$ = new BehaviorSubject({});
        this.filename = file.name;
        this.size = file.size;
        this.mime = file.mime;
    }
    Object.defineProperty(UploadQueueItem.prototype, "completed", {
        get: function () {
            return this.meta$.value.progress === 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadQueueItem.prototype, "pending", {
        get: function () {
            return this.meta$.value.progress == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadQueueItem.prototype, "hasError", {
        get: function () {
            return this.meta$.value.error != null;
        },
        enumerable: true,
        configurable: true
    });
    UploadQueueItem.prototype.update = function (data) {
        this.meta$.next(__assign({}, this.meta$.value, data));
    };
    UploadQueueItem.prototype.cancel = function () {
        this.canceled$.next(true);
        this.canceled$.complete();
    };
    UploadQueueItem.prototype.complete = function () {
        this.update({ progress: 100 });
    };
    UploadQueueItem.prototype.addError = function (message) {
        if (message === void 0) { message = ''; }
        this.update({ error: message });
    };
    return UploadQueueItem;
}());
export { UploadQueueItem };
