var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UploadValidator } from './upload-validator';
import { FileSizeValidation } from './validations/file-size-validation';
import { AllowedExtensionsValidation } from './validations/allowed-extensions-validation';
import { BlockedExtensionsValidation } from './validations/blocked-extensions-validation';
import { convertToBytes } from '../../core/utils/convertToBytes';
import * as i0 from "@angular/core";
import * as i1 from "../../core/config/settings.service";
import * as i2 from "../../core/ui/toast.service";
import * as i3 from "../../core/translations/translations.service";
var DefaultUploadValidator = /** @class */ (function (_super) {
    __extends(DefaultUploadValidator, _super);
    function DefaultUploadValidator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DEFAULT_MAX_FILE_SIZE_MB = 8;
        return _this;
    }
    DefaultUploadValidator.prototype.initValidations = function () {
        this.validations.push(new FileSizeValidation({ maxSize: this.getMaxFileSize() }, this.i18n));
        var allowedExtensions = this.getAllowedExtensions(), blockedExtensions = this.getBlockedExtensions();
        if (allowedExtensions && allowedExtensions.length) {
            this.validations.push(new AllowedExtensionsValidation({ extensions: allowedExtensions }, this.i18n));
        }
        if (blockedExtensions && blockedExtensions.length) {
            this.validations.push(new BlockedExtensionsValidation({ extensions: blockedExtensions }, this.i18n));
        }
    };
    DefaultUploadValidator.prototype.getMaxFileSize = function () {
        return this.settings.get('uploads.max_size', convertToBytes(this.DEFAULT_MAX_FILE_SIZE_MB, 'MB'));
    };
    DefaultUploadValidator.prototype.getAllowedExtensions = function () {
        return this.settings.getJson('uploads.allowed_extensions');
    };
    DefaultUploadValidator.prototype.getBlockedExtensions = function () {
        return this.settings.getJson('uploads.blocked_extensions');
    };
    DefaultUploadValidator.ngInjectableDef = i0.defineInjectable({ factory: function DefaultUploadValidator_Factory() { return new DefaultUploadValidator(i0.inject(i1.Settings), i0.inject(i2.Toast), i0.inject(i3.Translations)); }, token: DefaultUploadValidator, providedIn: "root" });
    return DefaultUploadValidator;
}(UploadValidator));
export { DefaultUploadValidator };
