var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ListState } from '../state/list-state';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReloadList, ResetState } from '../state/list-actions';
import { UserListsState } from '../user-lists/state/user-lists-state';
import { FormBuilder } from '@angular/forms';
import { LIST_SORT_OPTIONS } from '../types/list-sort-options';
import { CurrentUser } from '../../../../common/auth/current-user';
import { shareLinkSocially } from '../../../../common/core/utils/share-link-socially';
import { Settings } from '../../../../common/core/config/settings.service';
import { shareViaEmail } from '../../../../common/core/utils/share-via-email';
import { copyToClipboard } from '../../../../common/core/utils/copy-link-to-clipboard';
import { Translations } from '../../../../common/core/translations/translations.service';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
var ListPageComponent = /** @class */ (function () {
    function ListPageComponent(route, store, router, fb, currentUser, settings, i18n, toast) {
        this.route = route;
        this.store = store;
        this.router = router;
        this.fb = fb;
        this.currentUser = currentUser;
        this.settings = settings;
        this.i18n = i18n;
        this.toast = toast;
        this.sortOptions = LIST_SORT_OPTIONS;
        this.listForm = this.fb.group({
            sortBy: ['pivot.order'],
            sortDir: ['asc'],
        });
    }
    ListPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function () {
            _this.reloadList();
            _this.syncFormWithState();
        });
        this.listForm.valueChanges.subscribe(function () {
            _this.reloadList();
        });
    };
    ListPageComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new ResetState());
    };
    ListPageComponent.prototype.setSortDir = function (direction) {
        this.listForm.get('sortDir').setValue(direction);
    };
    ListPageComponent.prototype.sortIsActive = function (direction) {
        return this.listForm.get('sortDir').value === direction;
    };
    ListPageComponent.prototype.canEdit = function () {
        var list = this.store.selectSnapshot(ListState.list);
        return this.currentUser.get('id') === list.user_id;
    };
    ListPageComponent.prototype.syncFormWithState = function () {
        var params = this.store.selectSnapshot(ListState.params);
        this.listForm.setValue(params, { emitEvent: false });
    };
    ListPageComponent.prototype.reloadList = function () {
        var listId = this.route.snapshot.data.watchlist ?
            this.store.selectSnapshot(UserListsState.watchlist).id :
            this.route.snapshot.params.id;
        this.store.dispatch(new ReloadList(listId, this.listForm.value));
    };
    ListPageComponent.prototype.shareListSocially = function (type) {
        var list = this.store.selectSnapshot(ListState.list);
        var link = this.settings.getBaseUrl(true) + 'lists/' + list.id;
        if (type === 'mail') {
            var siteName = this.settings.get('branding.site_name');
            var subject = this.i18n.t('Check out this link on ') + siteName;
            var body = list.name + " - " + siteName + " - " + link;
            shareViaEmail(subject, body);
        }
        else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        }
        else {
            shareLinkSocially(type, link, list.name);
        }
    };
    __decorate([
        Select(ListState.list),
        __metadata("design:type", Observable)
    ], ListPageComponent.prototype, "list$", void 0);
    __decorate([
        Select(ListState.items),
        __metadata("design:type", Observable)
    ], ListPageComponent.prototype, "items$", void 0);
    __decorate([
        Select(ListState.loading),
        __metadata("design:type", Observable)
    ], ListPageComponent.prototype, "loading$", void 0);
    __decorate([
        Select(ListState.totalCount),
        __metadata("design:type", Observable)
    ], ListPageComponent.prototype, "totalCount$", void 0);
    __decorate([
        Select(ListState.currentCount),
        __metadata("design:type", Observable)
    ], ListPageComponent.prototype, "currentCount$", void 0);
    __decorate([
        Select(ListState.public),
        __metadata("design:type", Observable)
    ], ListPageComponent.prototype, "public$", void 0);
    return ListPageComponent;
}());
export { ListPageComponent };
