var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { Reset, SearchEverything, SetQuery } from './search-state-actions';
import { SearchService } from '../search.service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MEDIA_TYPE } from '../../media-type';
var SearchState = /** @class */ (function () {
    function SearchState(search) {
        this.search = search;
    }
    SearchState.results = function (state) {
        return state.results;
    };
    SearchState.movies = function (state) {
        return state.results.filter(function (result) { return result.type === MEDIA_TYPE.TITLE && !result.is_series; });
    };
    SearchState.series = function (state) {
        return state.results.filter(function (result) { return result.type === MEDIA_TYPE.TITLE && result.is_series; });
    };
    SearchState.people = function (state) {
        return state.results.filter(function (result) { return result.type === MEDIA_TYPE.PERSON; });
    };
    SearchState.query = function (state) {
        return state.query;
    };
    SearchState.prototype.searchEverything = function (ctx, action) {
        ctx.patchState({ query: action.query });
        if (!action.query || action.query.length < 3) {
            ctx.patchState({ results: [] });
            return of({ results: [] });
        }
        return this.search.everything(action.query, { type: action.type }).pipe(tap(function (response) {
            ctx.patchState({ results: response.results });
        }));
    };
    SearchState.prototype.setQuery = function (ctx, action) {
        ctx.patchState({ query: action.query });
    };
    SearchState.prototype.reset = function (ctx) {
        ctx.patchState({ results: [], query: '' });
    };
    __decorate([
        Action(SearchEverything),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SearchEverything]),
        __metadata("design:returntype", void 0)
    ], SearchState.prototype, "searchEverything", null);
    __decorate([
        Action(SetQuery),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetQuery]),
        __metadata("design:returntype", void 0)
    ], SearchState.prototype, "setQuery", null);
    __decorate([
        Action(Reset),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SearchState.prototype, "reset", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SearchState, "results", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SearchState, "movies", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SearchState, "series", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SearchState, "people", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SearchState, "query", null);
    SearchState = __decorate([
        State({
            name: 'search',
            defaults: {
                results: [],
                query: '',
            }
        }),
        __metadata("design:paramtypes", [SearchService])
    ], SearchState);
    return SearchState;
}());
export { SearchState };
