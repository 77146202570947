var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SearchState } from '../state/search-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { Reset } from '../state/search-state-actions';
var SearchPageComponent = /** @class */ (function () {
    function SearchPageComponent(store) {
        this.store = store;
    }
    SearchPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        // TODO: remove "setTimeout"
        setTimeout(function () {
            _this.store.dispatch(new ToggleGlobalLoader(false));
        });
    };
    SearchPageComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new Reset());
    };
    __decorate([
        Select(SearchState.query),
        __metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "query$", void 0);
    __decorate([
        Select(SearchState.movies),
        __metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "movies$", void 0);
    __decorate([
        Select(SearchState.series),
        __metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "series$", void 0);
    __decorate([
        Select(SearchState.people),
        __metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "people$", void 0);
    return SearchPageComponent;
}());
export { SearchPageComponent };
