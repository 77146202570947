<ng-content></ng-content>

<ng-container *ngIf="sliderList$ | async as sliderList">
    <div class="slider-track" slider (swipeleft)="changeSlide('previous')" (swiperight)="changeSlide('next')">
        <div class="slide" *ngFor="let item of filterTitles(sliderList.items)" [style.background-image]="'url('+ item.backdrop +')'">
            <div class="slide-cover">
                <div class="slide-content container">
                    <div class="rating">
                        <mat-icon svgIcon="star"></mat-icon>
                        {{item.rating}} / 10
                    </div>
                    <a class="title" [routerLink]="urls.mediaItem(item)" draggable="false">{{item.name}}</a>
                    <div class="description" moreLessText="350">{{item.description}}</div>
                    <genre-widget [genres]="item.genres"></genre-widget>
                    <button mat-raised-button color="accent" class="play-trailer-button" (click)="playVideo(item)" *ngIf="item?.videos?.length">
                        <mat-icon svgIcon="play-arrow"></mat-icon>
                        <span trans>Play Trailer</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-controls">
        <button class="control" *ngFor="let item of sliderList.items; index as index;" [class.active]="index === (activeSlide$ | async)" (tap)="changeSlide(index)">
            <span class="control-inner"></span>
        </button>
    </div>
</ng-container>

