var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Select, Store } from '@ngxs/store';
import { HomepageState } from '../state/homepage-state';
import { Observable } from 'rxjs';
import { ChangeSlide } from '../state/homepage-state.actions';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { MEDIA_TYPE } from '../../media-type';
import { PlayVideo } from '../../player/state/player-state-actions';
var SliderComponent = /** @class */ (function () {
    function SliderComponent(store, urls) {
        this.store = store;
        this.urls = urls;
    }
    SliderComponent.prototype.changeSlide = function (index) {
        var active = this.store.selectSnapshot(HomepageState.activeSlide);
        if (index === 'next') {
            index = active + 1;
        }
        else if (index === 'previous') {
            index = active - 1;
        }
        this.store.dispatch(new ChangeSlide(index));
    };
    SliderComponent.prototype.filterTitles = function (items) {
        return items.filter(function (item) {
            return item.type === MEDIA_TYPE.TITLE;
        });
    };
    SliderComponent.prototype.playVideo = function (title) {
        this.store.dispatch(new PlayVideo(title.videos[0], title));
    };
    __decorate([
        Select(HomepageState.sliderList),
        __metadata("design:type", Observable)
    ], SliderComponent.prototype, "sliderList$", void 0);
    __decorate([
        Select(HomepageState.activeSlide),
        __metadata("design:type", Observable)
    ], SliderComponent.prototype, "activeSlide$", void 0);
    return SliderComponent;
}());
export { SliderComponent };
