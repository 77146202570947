var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PersonState } from '../state/person-state';
import { Observable } from 'rxjs';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
var PersonPageComponent = /** @class */ (function () {
    function PersonPageComponent(urls, store, route, viewportScroller) {
        this.urls = urls;
        this.store = store;
        this.route = route;
        this.viewportScroller = viewportScroller;
    }
    PersonPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function () {
            _this.viewportScroller.scrollToPosition([0, 0]);
            _this.store.dispatch(new ToggleGlobalLoader(false));
        });
    };
    PersonPageComponent.prototype.trackByFn = function (title) {
        return title.id;
    };
    __decorate([
        Select(PersonState.person),
        __metadata("design:type", Observable)
    ], PersonPageComponent.prototype, "person$", void 0);
    __decorate([
        Select(PersonState.credits),
        __metadata("design:type", Observable)
    ], PersonPageComponent.prototype, "credits$", void 0);
    __decorate([
        Select(PersonState.knownFor),
        __metadata("design:type", Observable)
    ], PersonPageComponent.prototype, "knownFor$", void 0);
    __decorate([
        Select(PersonState.backdrop),
        __metadata("design:type", Observable)
    ], PersonPageComponent.prototype, "backdrop$", void 0);
    __decorate([
        Select(PersonState.creditsCount),
        __metadata("design:type", Observable)
    ], PersonPageComponent.prototype, "creditsCount$", void 0);
    return PersonPageComponent;
}());
export { PersonPageComponent };
