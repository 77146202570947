var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State, Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { tap } from 'rxjs/operators';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { LoadPerson, SetPerson } from './person-state-actions';
import { PeopleService } from '../people.service';
var PersonState = /** @class */ (function () {
    function PersonState(store, people) {
        this.store = store;
        this.people = people;
    }
    PersonState.person = function (state) {
        return state.person;
    };
    PersonState.credits = function (state) {
        return state.credits;
    };
    PersonState.creditsCount = function (state) {
        return Object.keys(state.credits).map(function (department) {
            return state.credits[department].length;
        }).reduce(function (a, b) { return a + b; }, 0);
    };
    PersonState.knownFor = function (state) {
        return state.knownFor;
    };
    PersonState.backdrop = function (state) {
        var titleWithBackdrop = state.knownFor.find(function (title) { return !!title.backdrop; });
        return titleWithBackdrop ? titleWithBackdrop.backdrop : null;
    };
    PersonState.prototype.loadPerson = function (ctx) {
        var _this = this;
        var state = ctx.getState(), params = this.store.selectSnapshot(RouterState.state).root.firstChild.firstChild.params;
        if (state.person && state.person.id === +params.id)
            return;
        return this.people.get(params.id).pipe(tap(function (response) {
            _this.store.dispatch(new SetPerson(response));
        }));
    };
    PersonState.prototype.setTitle = function (ctx, action) {
        ctx.patchState({
            person: action.response.person,
            credits: action.response.credits,
            knownFor: action.response.knownFor,
        });
        this.store.dispatch(new ToggleGlobalLoader(false));
    };
    __decorate([
        Action(LoadPerson),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PersonState.prototype, "loadPerson", null);
    __decorate([
        Action(SetPerson),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetPerson]),
        __metadata("design:returntype", void 0)
    ], PersonState.prototype, "setTitle", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PersonState, "person", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PersonState, "credits", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], PersonState, "creditsCount", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PersonState, "knownFor", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PersonState, "backdrop", null);
    PersonState = __decorate([
        State({
            name: 'person',
            defaults: {
                knownFor: [],
            }
        }),
        __metadata("design:paramtypes", [Store,
            PeopleService])
    ], PersonState);
    return PersonState;
}());
export { PersonState };
