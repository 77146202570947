var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Bootstrapper } from '../common/core/bootstrapper.service';
import { Store } from '@ngxs/store';
import { SetRatings, SetWatchlist } from './site/lists/user-lists/state/user-lists-state-actions';
var AppBootstrapperService = /** @class */ (function (_super) {
    __extends(AppBootstrapperService, _super);
    function AppBootstrapperService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Handle specified bootstrap data.
     */
    AppBootstrapperService.prototype.handleData = function (encodedData) {
        var data = _super.prototype.handleData.call(this, encodedData);
        // set user watchlist
        this.injector.get(Store).dispatch(new SetWatchlist(data['watchlist']));
        // set user ratings
        this.injector.get(Store).dispatch(new SetRatings(data['ratings']));
        return data;
    };
    return AppBootstrapperService;
}(Bootstrapper));
export { AppBootstrapperService };
