<ng-container *ngIf="episodes$ | async as episodes">
    <div class="episode current" *ngFor="let episode of episodes; index as index">
        <div class="header">
        <span trans>
            <ng-container *ngIf="index === 0">Current</ng-container>
            <ng-container *ngIf="index === 1">Next</ng-container>
            Episode
        </span>&nbsp;
            <season-episode-number [episode]="episode"></season-episode-number>
        </div>

        <div class="media">
            <a class="img-container" [routerLink]="getEpisodeUrl(episode)">
                <img [src]="episode.poster" alt="{{episode.name}} poster">
            </a>
            <div class="media-body">
                <a [routerLink]="getEpisodeUrl(episode)" class="name">{{episode.name}}</a>
                <div class="release-date">{{episode.release_date | formattedDate}}</div>
                <div class="description" moreLessText="130">{{episode.description}}</div>
            </div>
        </div>
    </div>
</ng-container>