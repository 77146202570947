/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./current-next-episode-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "@angular/common";
import * as i6 from "../season-episode-number/season-episode-number.component.ngfactory";
import * as i7 from "../season-episode-number/season-episode-number.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../shared/more-less-text.directive";
import * as i10 from "../../../../../common/core/ui/formatted-date.pipe";
import * as i11 from "./current-next-episode-panel.component";
import * as i12 from "../../title-urls.service";
import * as i13 from "@ngxs/store";
var styles_CurrentNextEpisodePanelComponent = [i0.styles];
var RenderType_CurrentNextEpisodePanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CurrentNextEpisodePanelComponent, data: {} });
export { RenderType_CurrentNextEpisodePanelComponent as RenderType_CurrentNextEpisodePanelComponent };
function View_CurrentNextEpisodePanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current"]))], null, null); }
function View_CurrentNextEpisodePanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"]))], null, null); }
function View_CurrentNextEpisodePanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "episode current"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentNextEpisodePanelComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentNextEpisodePanelComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" Episode "])), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "season-episode-number", [], null, null, null, i6.View_SeasonEpisodeNumberComponent_0, i6.RenderType_SeasonEpisodeNumberComponent)), i1.ɵdid(11, 49152, null, 0, i7.SeasonEpisodeNumberComponent, [], { episode: [0, "episode"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 13, "div", [["class", "media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "img-container"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 9, "div", [["class", "media-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "a", [["class", "name"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(19, null, ["", ""])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "release-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), i1.ɵppd(22, 1), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "description"], ["moreLessText", "130"]], null, null, null, null, null)), i1.ɵdid(24, 4210688, null, 0, i9.MoreLessTextDirective, [i1.ElementRef, i3.Translations], { limit: [0, "limit"] }, null), (_l()(), i1.ɵted(25, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_v.context.index === 1); _ck(_v, 7, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 11, 0, currVal_2); var currVal_5 = _co.getEpisodeUrl(_v.context.$implicit); _ck(_v, 14, 0, currVal_5); var currVal_10 = _co.getEpisodeUrl(_v.context.$implicit); _ck(_v, 18, 0, currVal_10); var currVal_13 = "130"; _ck(_v, 24, 0, currVal_13); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 14).target; var currVal_4 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.poster; var currVal_7 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, " poster"); _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v, 18).target; var currVal_9 = i1.ɵnov(_v, 18).href; _ck(_v, 17, 0, currVal_8, currVal_9); var currVal_11 = _v.context.$implicit.name; _ck(_v, 19, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 21, 0, _ck(_v, 22, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.release_date)); _ck(_v, 21, 0, currVal_12); var currVal_14 = _v.context.$implicit.description; _ck(_v, 25, 0, currVal_14); }); }
function View_CurrentNextEpisodePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentNextEpisodePanelComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CurrentNextEpisodePanelComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i10.FormattedDatePipe, [i4.Settings, i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CurrentNextEpisodePanelComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.episodes$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CurrentNextEpisodePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "current-next-episode-panel", [], null, null, null, View_CurrentNextEpisodePanelComponent_0, RenderType_CurrentNextEpisodePanelComponent)), i1.ɵdid(1, 49152, null, 0, i11.CurrentNextEpisodePanelComponent, [i12.TitleUrlsService, i13.Store], null, null)], null, null); }
var CurrentNextEpisodePanelComponentNgFactory = i1.ɵccf("current-next-episode-panel", i11.CurrentNextEpisodePanelComponent, View_CurrentNextEpisodePanelComponent_Host_0, {}, {}, []);
export { CurrentNextEpisodePanelComponentNgFactory as CurrentNextEpisodePanelComponentNgFactory };
