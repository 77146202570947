var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { TitleUrlsService } from '../../title-urls.service';
import { map } from 'rxjs/operators';
var FullCreditsPageComponent = /** @class */ (function () {
    function FullCreditsPageComponent(store, urls) {
        this.store = store;
        this.urls = urls;
        this.groupedCredits$ = this.store.select(TitleState.titleOrEpisodeCredits)
            .pipe(map(this.groupCredits));
    }
    FullCreditsPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.store.dispatch(new ToggleGlobalLoader(false)); });
    };
    FullCreditsPageComponent.prototype.trackByFn = function (title) {
        return title.id;
    };
    FullCreditsPageComponent.prototype.groupCredits = function (credits) {
        return credits.reduce(function (h, a) {
            var _a;
            return Object.assign(h, (_a = {}, _a[a.pivot.department] = (h[a.pivot.department] || []).concat(a), _a));
        }, {});
    };
    __decorate([
        Select(TitleState.title),
        __metadata("design:type", Observable)
    ], FullCreditsPageComponent.prototype, "title$", void 0);
    __decorate([
        Select(TitleState.backdrop),
        __metadata("design:type", Observable)
    ], FullCreditsPageComponent.prototype, "backdropImage$", void 0);
    return FullCreditsPageComponent;
}());
export { FullCreditsPageComponent };
