var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { Person } from '../../../../models/person';
import { finalize, tap } from 'rxjs/operators';
import { CreatePerson, DetachCredit, LoadPerson, ResetState, UpdatePerson } from './crupdate-person-state-actions';
import { PeopleService } from '../../../../site/people/people.service';
import { TitlesService } from '../../../../site/titles/titles.service';
var CrupdatePersonState = /** @class */ (function () {
    function CrupdatePersonState(people, titles) {
        this.people = people;
        this.titles = titles;
    }
    CrupdatePersonState.person = function (state) {
        return state.person;
    };
    CrupdatePersonState.loading = function (state) {
        return state.loading;
    };
    CrupdatePersonState.credits = function (state) {
        return state.credits;
    };
    CrupdatePersonState.prototype.loadPerson = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        return this.people.get(action.id).pipe(tap(function (response) {
            ctx.patchState({
                person: response.person,
                credits: _this.flattenCredits(response.credits),
                loading: false
            });
        }));
    };
    CrupdatePersonState.prototype.createPerson = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.people.create(action.payload).pipe(finalize(function () { return ctx.patchState({ loading: false }); }), tap(function (response) { return ctx.patchState({ person: response.person }); }));
    };
    CrupdatePersonState.prototype.updatePerson = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.people.update(ctx.getState().person.id, action.payload).pipe(finalize(function () { return ctx.patchState({ loading: false }); }), tap(function (response) { return ctx.patchState({ person: response.person }); }));
    };
    CrupdatePersonState.prototype.removeCredit = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.removeCredit(action.id).pipe(tap(function () {
            var newCredits = ctx.getState().credits.filter(function (credit) {
                return credit.pivot.id !== action.id;
            });
            ctx.patchState({ credits: newCredits });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdatePersonState.prototype.resetState = function (ctx) {
        ctx.patchState({
            person: new Person(),
            credits: [],
            loading: false,
        });
    };
    CrupdatePersonState.prototype.flattenCredits = function (credits) {
        var flatCredits = [];
        Object.keys(credits).forEach(function (key) {
            flatCredits.push.apply(flatCredits, credits[key]);
        });
        return flatCredits;
    };
    __decorate([
        Action(LoadPerson),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, LoadPerson]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState.prototype, "loadPerson", null);
    __decorate([
        Action(CreatePerson),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, CreatePerson]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState.prototype, "createPerson", null);
    __decorate([
        Action(UpdatePerson),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdatePerson]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState.prototype, "updatePerson", null);
    __decorate([
        Action(DetachCredit),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, DetachCredit]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState.prototype, "removeCredit", null);
    __decorate([
        Action(ResetState),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState.prototype, "resetState", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState, "person", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState, "loading", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CrupdatePersonState, "credits", null);
    CrupdatePersonState = __decorate([
        State({
            name: 'crupdatePerson',
            defaults: {
                person: new Person(),
                credits: [],
                loading: false,
            },
        }),
        __metadata("design:paramtypes", [PeopleService,
            TitlesService])
    ], CrupdatePersonState);
    return CrupdatePersonState;
}());
export { CrupdatePersonState };
