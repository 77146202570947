import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {FormBuilder} from '@angular/forms';
import {LoadFilterOptions, LoadMoreTitles, ReloadTitles} from '../../state/browse/browse-title-actions';
import {BrowseTitleState} from '../../state/browse/browse-title.state';
import {Observable} from 'rxjs';
import {Title} from '../../../../models/title';
import {ActivatedRoute} from '@angular/router';
import {MatSelectionList} from '@angular/material';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BreakpointsService} from '../../../../../common/core/ui/breakpoints.service';
import {CountryListItem, LanguageListItem} from '../../../../../common/core/services/value-lists.service';
import {InfiniteScroll} from '../../../../../common/core/ui/infinite-scroll/infinite.scroll';
import {objectsAreEqual} from '../../../../../common/core/utils/objects-are-equal';

@Component({
    selector: 'browse-titles',
    templateUrl: './browse-titles.component.html',
    styleUrls: ['./browse-titles.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowseTitlesComponent extends InfiniteScroll implements OnInit {
    @ViewChild('genreList') genreList: MatSelectionList;
    @Select(BrowseTitleState.titles) titles$: Observable<Title[]>;
    @Select(BrowseTitleState.doesNotHaveResults) doesNotHaveResults$: Observable<boolean>;
    @Select(BrowseTitleState.loading) loading$: Observable<boolean>;
    @Select(BrowseTitleState.anyFilterActive) anyFilterActive$: Observable<boolean>;
    @Select(BrowseTitleState.countries) countries$: Observable<CountryListItem[]>;
    @Select(BrowseTitleState.languages) languages$: Observable<LanguageListItem[]>;
    @Select(BrowseTitleState.genres) genres$: Observable<string[]>;
    @Select(BrowseTitleState.certifications) certifications$: Observable<string[]>;

    public form = this.fb.group({
        type: [],
        genre: [],
        released: [],
        score: [],
        country: [],
        language: [],
        runtime: [],
        certification: [],
        order: [],
    });

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        public breakpoints: BreakpointsService,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.store.dispatch(new LoadFilterOptions());
        // reload titles when form is updated
        this.form.valueChanges
            .pipe(debounceTime(50), distinctUntilChanged((a, b) => objectsAreEqual(a, b)))
            .subscribe(value => {
                this.store.dispatch(new ReloadTitles(value));
            });

        this.route.queryParams.subscribe(params => {
            this.form.patchValue(params);
        });

        // patch form with initial filters from query params
        this.form.patchValue(
            this.store.selectSnapshot(BrowseTitleState.filters)
        );
    }

    public clearAllFilters() {
        this.form.reset();
    }

    protected loadMoreItems() {
        this.store.dispatch(new LoadMoreTitles());
    }

    protected canLoadMore() {
        return this.store.selectSnapshot(BrowseTitleState.canLoadMore);
    }

    protected isLoading() {
        return this.store.selectSnapshot(BrowseTitleState.loading);
    }
}
