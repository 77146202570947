var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { TitleState } from '../../state/title-state';
import { Select, Store } from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { TitleUrlsService } from '../../title-urls.service';
import { filter } from 'rxjs/operators';
var CurrentNextEpisodePanelComponent = /** @class */ (function () {
    function CurrentNextEpisodePanelComponent(urls, store) {
        this.urls = urls;
        this.store = store;
    }
    Object.defineProperty(CurrentNextEpisodePanelComponent.prototype, "episodes$", {
        get: function () {
            return zip(this.currentEpisode$, this.nextEpisode$).pipe(filter(function (episodes) {
                return !!episodes[0] && !!episodes[1];
            }));
        },
        enumerable: true,
        configurable: true
    });
    CurrentNextEpisodePanelComponent.prototype.getEpisodeUrl = function (episode) {
        var series = this.store.selectSnapshot(TitleState.title);
        return this.urls.episode(series, episode.season_number, episode.episode_number);
    };
    __decorate([
        Select(TitleState.nextEpisode),
        __metadata("design:type", Observable)
    ], CurrentNextEpisodePanelComponent.prototype, "nextEpisode$", void 0);
    __decorate([
        Select(TitleState.currentEpisode),
        __metadata("design:type", Observable)
    ], CurrentNextEpisodePanelComponent.prototype, "currentEpisode$", void 0);
    return CurrentNextEpisodePanelComponent;
}());
export { CurrentNextEpisodePanelComponent };
