import { ElementRef, OnChanges } from '@angular/core';
import { OverlayPanel } from '../../../../../common/core/ui/overlay-panel/overlay-panel.service';
import { StarRatingOverlayComponent } from './star-rating-overlay/star-rating-overlay.component';
import { RIGHT_POSITION } from '../../../../../common/core/ui/overlay-panel/positions/right-position';
import { Store } from '@ngxs/store';
import { UserListsState } from '../../../lists/user-lists/state/user-lists-state';
import { BehaviorSubject } from 'rxjs';
var RatingWidgetComponent = /** @class */ (function () {
    function RatingWidgetComponent(overlay, store) {
        this.overlay = overlay;
        this.store = store;
        this.showRateButton = true;
        this.userRating$ = new BehaviorSubject(null);
    }
    Object.defineProperty(RatingWidgetComponent.prototype, "doesNotHaveRating", {
        get: function () {
            return !this.item || !this.item.rating;
        },
        enumerable: true,
        configurable: true
    });
    RatingWidgetComponent.prototype.ngOnChanges = function () {
        if (!this.item)
            return;
        this.updateUserRating();
    };
    RatingWidgetComponent.prototype.openRatingOverlay = function (e) {
        var _this = this;
        this.overlay.open(StarRatingOverlayComponent, {
            origin: new ElementRef(e.target),
            position: RIGHT_POSITION,
            data: { item: this.item, userRating: this.userRating$.value }
        }).afterClosed().subscribe(function () {
            _this.updateUserRating();
        });
    };
    RatingWidgetComponent.prototype.updateUserRating = function () {
        var _this = this;
        var rating = this.store.selectSnapshot(UserListsState.ratings).find(function (review) {
            return review.reviewable_id === _this.item.id && review.reviewable_type === _this.item.type;
        });
        this.userRating$.next(rating);
    };
    return RatingWidgetComponent;
}());
export { RatingWidgetComponent };
