var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleUrlsService } from '../../title-urls.service';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { filter } from 'rxjs/operators';
var SeasonPageComponent = /** @class */ (function () {
    function SeasonPageComponent(route, router, store, urls) {
        this.route = route;
        this.router = router;
        this.store = store;
        this.urls = urls;
        this.seasonModel = new FormControl();
    }
    SeasonPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        // TODO: remove settimout
        setTimeout(function () {
            _this.store.dispatch(new ToggleGlobalLoader(false));
        });
        this.store.select(TitleState.season)
            .pipe(filter(function (season) { return !!season; }))
            .subscribe(function (season) {
            _this.seasonModel.setValue(season.number, {
                emitEvent: false
            });
        });
        this.seasonModel.valueChanges.subscribe(function (seasonNumber) {
            var title = _this.store.selectSnapshot(TitleState.title);
            _this.router.navigate(_this.urls.season(title, seasonNumber));
        });
    };
    __decorate([
        Select(TitleState.episodes),
        __metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "episodes$", void 0);
    __decorate([
        Select(TitleState.title),
        __metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "title$", void 0);
    __decorate([
        Select(TitleState.seasonNumbers),
        __metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "seasonNumbers$", void 0);
    __decorate([
        Select(TitleState.backdrop),
        __metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "backdropImage$", void 0);
    return SeasonPageComponent;
}());
export { SeasonPageComponent };
