var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../state/title-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { Settings } from '../../../../common/core/config/settings.service';
import { OverlayPanel } from '../../../../common/core/ui/overlay-panel/overlay-panel.service';
import { ImageGalleryOverlayComponent } from '../../shared/image-gallery-overlay/image-gallery-overlay.component';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
var TitlePageContainerComponent = /** @class */ (function () {
    function TitlePageContainerComponent(store, settings, overlay, route, viewportScroller) {
        this.store = store;
        this.settings = settings;
        this.overlay = overlay;
        this.route = route;
        this.viewportScroller = viewportScroller;
    }
    TitlePageContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        // scroll to top when title changes as
        // component will be re-used by angular
        this.route.params.subscribe(function () {
            _this.viewportScroller.scrollToPosition([0, 0]);
            setTimeout(function () { return _this.store.dispatch(new ToggleGlobalLoader(false)); });
        });
    };
    TitlePageContainerComponent.prototype.openImageGallery = function (images, currentImage) {
        this.overlay.open(ImageGalleryOverlayComponent, {
            origin: 'global',
            position: 'center',
            panelClass: 'image-gallery-overlay-container',
            backdropClass: 'image-gallery-overlay-backdrop',
            hasBackdrop: true,
            data: { images: images, currentImage: currentImage }
        });
    };
    __decorate([
        Select(TitleState.title),
        __metadata("design:type", Observable)
    ], TitlePageContainerComponent.prototype, "title$", void 0);
    __decorate([
        Select(TitleState.backdrop),
        __metadata("design:type", Observable)
    ], TitlePageContainerComponent.prototype, "backdropImage$", void 0);
    return TitlePageContainerComponent;
}());
export { TitlePageContainerComponent };
