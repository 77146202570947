var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { CurrentUser } from '../../../../common/auth/current-user';
import { Select, Store } from '@ngxs/store';
import { LoadUserLists } from './state/user-lists-state-actions';
import { UserListsState } from './state/user-lists-state';
import { Observable } from 'rxjs';
var UserListsComponent = /** @class */ (function () {
    function UserListsComponent(store, currentUser) {
        this.store = store;
        this.currentUser = currentUser;
    }
    UserListsComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new LoadUserLists());
    };
    __decorate([
        Select(UserListsState.lists),
        __metadata("design:type", Observable)
    ], UserListsComponent.prototype, "lists$", void 0);
    __decorate([
        Select(UserListsState.loading),
        __metadata("design:type", Observable)
    ], UserListsComponent.prototype, "loading$", void 0);
    return UserListsComponent;
}());
export { UserListsComponent };
