/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../common/core/ui/auth-click.directive";
import * as i8 from "../../../../../common/auth/current-user";
import * as i9 from "@angular/router";
import * as i10 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../../../common/core/translations/translate.directive";
import * as i13 from "../../../../../common/core/translations/translations.service";
import * as i14 from "../../../../../common/core/config/settings.service";
import * as i15 from "@angular/common";
import * as i16 from "./rating-widget.component";
import * as i17 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i18 from "@ngxs/store";
var styles_RatingWidgetComponent = [i0.styles];
var RenderType_RatingWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RatingWidgetComponent, data: {} });
export { RenderType_RatingWidgetComponent as RenderType_RatingWidgetComponent };
function View_RatingWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "button", [["class", "user-rating rate-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "authClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("authClick" === en)) {
        var pd_1 = (_co.openRatingOverlay($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 16384, null, 0, i7.AuthClickDirective, [i8.CurrentUser, i9.Router], null, { authClick: "authClick" }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "star"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 6, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(7, 4341760, null, 0, i12.TranslateDirective, [i1.ElementRef, i13.Translations, i14.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Your Score"])), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "score"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", ""]))], function (_ck, _v) { var currVal_3 = "star"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_2); var currVal_4 = _v.context.ngIf.score; _ck(_v, 11, 0, currVal_4); }); }
function View_RatingWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "rate-this rate-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "authClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("authClick" === en)) {
        var pd_1 = (_co.openRatingOverlay($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 16384, null, 0, i7.AuthClickDirective, [i8.CurrentUser, i9.Router], null, { authClick: "authClick" }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "star-border"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "span", [["class", "text"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(6, 4341760, null, 0, i12.TranslateDirective, [i1.ElementRef, i13.Translations, i14.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Rate This"]))], function (_ck, _v) { var currVal_3 = "star-border"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_2); }); }
function View_RatingWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RatingWidgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i15.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["rateThis", 2]], null, 0, null, View_RatingWidgetComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.userRating$)); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_RatingWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "current-rating"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "star"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " / 10"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingWidgetComponent_1)), i1.ɵdid(6, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "star"; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.showRateButton; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.item.rating; _ck(_v, 4, 0, currVal_2); }); }
export function View_RatingWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating-widget", [], [[2, "hidden", null]], null, null, View_RatingWidgetComponent_0, RenderType_RatingWidgetComponent)), i1.ɵdid(1, 573440, null, 0, i16.RatingWidgetComponent, [i17.OverlayPanel, i18.Store], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).doesNotHaveRating; _ck(_v, 0, 0, currVal_0); }); }
var RatingWidgetComponentNgFactory = i1.ɵccf("rating-widget", i16.RatingWidgetComponent, View_RatingWidgetComponent_Host_0, { item: "item", showRateButton: "showRateButton" }, {}, []);
export { RatingWidgetComponentNgFactory as RatingWidgetComponentNgFactory };
