var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PlayerState } from '../state/player-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { youtubePlayerUrlParams } from '../youtube-player-params';
import { OverlayPanelRef } from '../../../../common/core/ui/overlay-panel/overlay-panel-ref';
import { PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from '../state/player-state-actions';
import { BreakpointsService } from '../../../../common/core/ui/breakpoints.service';
var PlayerComponent = /** @class */ (function () {
    function PlayerComponent(store, sanitizer, overlayRef, breakpoints) {
        this.store = store;
        this.sanitizer = sanitizer;
        this.overlayRef = overlayRef;
        this.breakpoints = breakpoints;
        this.safeUrl$ = new BehaviorSubject(null);
        this.minimized = false;
    }
    PlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new PlayerOverlayOpened());
        this.activeVideo$.subscribe(function (video) {
            _this.safeUrl$.next(_this.sanitizer.bypassSecurityTrustResourceUrl(video.url + youtubePlayerUrlParams()));
        });
        // hide sidebar on mobile
        if (this.breakpoints.isMobile) {
            this.toggleSidebar();
        }
    };
    PlayerComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new PlayerOverlayClosed());
    };
    PlayerComponent.prototype.playVideo = function (video) {
        this.store.dispatch(new PlayVideo(video, video.title));
    };
    PlayerComponent.prototype.toggleSidebar = function () {
        this.store.dispatch(new ToggleSidebar());
    };
    PlayerComponent.prototype.close = function () {
        this.overlayRef.close();
    };
    __decorate([
        Select(PlayerState.activeVideo),
        __metadata("design:type", Observable)
    ], PlayerComponent.prototype, "activeVideo$", void 0);
    __decorate([
        Select(PlayerState.relatedVideos),
        __metadata("design:type", Observable)
    ], PlayerComponent.prototype, "relatedVideos$", void 0);
    __decorate([
        Select(PlayerState.mediaItem),
        __metadata("design:type", Observable)
    ], PlayerComponent.prototype, "mediaItem$", void 0);
    __decorate([
        Select(PlayerState.sidebarOpen),
        __metadata("design:type", Observable)
    ], PlayerComponent.prototype, "sidebarOpen$", void 0);
    return PlayerComponent;
}());
export { PlayerComponent };
