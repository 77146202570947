import {Injectable} from '@angular/core';
import {TitleService} from '../common/core/services/title.service';
import {MEDIA_TYPE} from './site/media-type';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Translations} from '../common/core/translations/translations.service';
import {Settings} from '../common/core/config/settings.service';
import {Store} from '@ngxs/store';
import {TitleState} from './site/titles/state/title-state';
import {PersonState} from './site/people/state/person-state';

@Injectable({
    providedIn: 'root'
})
export class PageTitleService extends TitleService {
    constructor(
        protected router: Router,
        protected title: Title,
        protected i18n: Translations,
        protected settings: Settings,
        protected store: Store,
    ) {
        super(router, title, i18n, settings);
    }

    protected getTitle(data): string {
        switch (data.name) {
            case MEDIA_TYPE.MOVIE:
            case MEDIA_TYPE.SERIES:
            case MEDIA_TYPE.SEASON:
            case MEDIA_TYPE.EPISODE:
                return this.getMovieTitle();
            case MEDIA_TYPE.PERSON:
                return this.getPersonTitle();
            case 'browse':
                return this.i18n.t('Browse');
            case 'people':
                return this.i18n.t('Popular People');
            case 'news':
                return this.i18n.t('Latest News');
            case 'userLists':
                return this.i18n.t('User Lists');
            case 'watchlist':
                return this.i18n.t('Your Watchlist');
            case 'homepage':
                return this.i18n.t('MTDb - Movies, TV and Celebrities');
            default:
                return super.getTitle(data);
        }
    }

    private getMovieTitle() {
        const title = this.store.selectSnapshot(TitleState.title);
        return title.name + ' (' + title.year + ')';
    }

    private getPersonTitle() {
        const person = this.store.selectSnapshot(PersonState.person);
        return person.name;
    }
}
