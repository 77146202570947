var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Select, Store } from '@ngxs/store';
import { AddToWatchlist, RemoveFromWatchlist } from '../../../lists/user-lists/state/user-lists-state-actions';
import { UserListsState } from '../../../lists/user-lists/state/user-lists-state';
import { Observable } from 'rxjs';
var TitleActionButtonsComponent = /** @class */ (function () {
    function TitleActionButtonsComponent(store) {
        this.store = store;
    }
    TitleActionButtonsComponent.prototype.isInWatchlist = function () {
        var _this = this;
        var watchlist = this.store.selectSnapshot(UserListsState.watchlist);
        if (!watchlist)
            return false;
        return watchlist.items.findIndex(function (i) { return i.id === _this.item.id && i.type === _this.item.type; }) > -1;
    };
    TitleActionButtonsComponent.prototype.addToWatchlist = function () {
        this.store.dispatch(new AddToWatchlist(this.item));
    };
    TitleActionButtonsComponent.prototype.removeFromWatchlist = function () {
        this.store.dispatch(new RemoveFromWatchlist(this.item));
    };
    __decorate([
        Select(UserListsState.loading),
        __metadata("design:type", Observable)
    ], TitleActionButtonsComponent.prototype, "loading$", void 0);
    return TitleActionButtonsComponent;
}());
export { TitleActionButtonsComponent };
