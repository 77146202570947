var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Modal } from '../../../../common/core/ui/dialogs/modal.service';
import { CrupdateReviewModalComponent } from '../crupdate-review-modal/crupdate-review-modal.component';
import { Select, Store } from '@ngxs/store';
import { CrupdateReview, DeleteReview, LoadReviews } from '../../titles/state/title-actions';
import { TitleState } from '../../titles/state/title-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUser } from '../../../../common/auth/current-user';
import { ReviewScoreType } from './review-score-type.enum';
import { finalize } from 'rxjs/operators';
import { ReviewService } from '../../shared/review.service';
import { ConfirmModalComponent } from '../../../../common/core/ui/confirm-modal/confirm-modal.component';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
var ReviewTabComponent = /** @class */ (function () {
    function ReviewTabComponent(modal, store, currentUser, reviews, toast) {
        this.modal = modal;
        this.store = store;
        this.currentUser = currentUser;
        this.reviews = reviews;
        this.toast = toast;
        this.loading$ = new BehaviorSubject(false);
    }
    ReviewTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading$.next(true);
        this.store.dispatch(new LoadReviews())
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe();
    };
    ReviewTabComponent.prototype.openCrupdateReviewModal = function () {
        var _this = this;
        var review = this.store.selectSnapshot(TitleState.reviews)
            .find(function (curr) { return curr.user_id === _this.currentUser.get('id'); });
        var mediaId = this.store.selectSnapshot(TitleState.title).id;
        this.modal.open(CrupdateReviewModalComponent, { review: review, mediaId: mediaId }, 'crupdate-review-modal-container').beforeClosed().subscribe(function (newReview) {
            if (newReview) {
                _this.store.dispatch(new CrupdateReview(newReview));
            }
        });
    };
    ReviewTabComponent.prototype.maybeDeleteReview = function (review) {
        var _this = this;
        this.modal.show(ConfirmModalComponent, {
            title: 'Delete Review',
            body: 'Are you sure you want to delete your review?',
            ok: 'Delete'
        }).afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            _this.loading$.next(true);
            _this.store.dispatch(new DeleteReview(review))
                .pipe(finalize(function () { return _this.loading$.next(false); }))
                .subscribe(function () { return _this.toast.open(MESSAGES.REVIEW_DELETE_SUCCESS); });
        });
    };
    ReviewTabComponent.prototype.getScoreColor = function (score) {
        if (score < 5) {
            return ReviewScoreType.LOW;
        }
        else if (score < 7) {
            return ReviewScoreType.MEDIUM;
        }
        else {
            return ReviewScoreType.HIGH;
        }
    };
    __decorate([
        Select(TitleState.reviews),
        __metadata("design:type", Observable)
    ], ReviewTabComponent.prototype, "reviews$", void 0);
    return ReviewTabComponent;
}());
export { ReviewTabComponent };
