var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnChanges, SimpleChange } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { PlayVideo } from '../../../player/state/player-state-actions';
import { MEDIA_TYPE } from '../../../media-type';
var TitleSecondaryDetailsPanelComponent = /** @class */ (function () {
    function TitleSecondaryDetailsPanelComponent(store) {
        this.store = store;
    }
    TitleSecondaryDetailsPanelComponent.prototype.ngOnChanges = function (changes) {
        if (changes.item.currentValue && changes.item.currentValue.credits) {
            this.setCrew();
        }
    };
    TitleSecondaryDetailsPanelComponent.prototype.playVideo = function () {
        var title = this.store.selectSnapshot(TitleState.title), video = title.videos[0];
        this.store.dispatch(new PlayVideo(video, title));
    };
    TitleSecondaryDetailsPanelComponent.prototype.isSeries = function () {
        return this.item.type === MEDIA_TYPE.TITLE && this.item.is_series;
    };
    TitleSecondaryDetailsPanelComponent.prototype.setCrew = function () {
        var credits = this.store.selectSnapshot(TitleState.titleOrEpisodeCredits);
        this.credits = {
            director: this.getDirector(credits),
            writers: this.getWriters(credits),
            cast: this.getCast(credits),
            creators: this.getCreators(credits),
        };
    };
    TitleSecondaryDetailsPanelComponent.prototype.getDirector = function (credits) {
        return credits.find(function (person) { return person.pivot.department === 'directing'; });
    };
    TitleSecondaryDetailsPanelComponent.prototype.getWriters = function (credits) {
        return credits.filter(function (person) { return person.pivot.department === 'writing'; });
    };
    TitleSecondaryDetailsPanelComponent.prototype.getCast = function (credits) {
        return credits.filter(function (person) { return person.pivot.department === 'cast'; }).slice(0, 3);
    };
    TitleSecondaryDetailsPanelComponent.prototype.getCreators = function (credits) {
        return credits.filter(function (person) { return person.pivot.department === 'creators'; });
    };
    __decorate([
        Select(TitleState.title),
        __metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "title$", void 0);
    __decorate([
        Select(TitleState.videoCoverImage),
        __metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "videoCoverImage$", void 0);
    __decorate([
        Select(TitleState.trailer),
        __metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "trailer$", void 0);
    __decorate([
        Select(TitleState.seasonNumbers),
        __metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "seasonNumbers$", void 0);
    return TitleSecondaryDetailsPanelComponent;
}());
export { TitleSecondaryDetailsPanelComponent };
