/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./star-rating-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/common";
import * as i10 from "@angular/forms";
import * as i11 from "./star-rating-widget.component";
var styles_StarRatingWidgetComponent = [i0.styles];
var RenderType_StarRatingWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StarRatingWidgetComponent, data: {} });
export { RenderType_StarRatingWidgetComponent as RenderType_StarRatingWidgetComponent };
function View_StarRatingWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "star-border"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "star-border"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_StarRatingWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "star-button mat-icon"], ["role", "img"], ["svgIcon", "star"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "star"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_StarRatingWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-icon-button", ""], ["tabindex", "-1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.setRating((_v.context.index + 1)) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.setRatingAndPropagate((_v.context.index + 1)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_StarRatingWidgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["fullStar", 2]], 0, 0, null, View_StarRatingWidgetComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.rating$)) < (_v.context.index + 1)); var currVal_3 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_StarRatingWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "stars"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StarRatingWidgetComponent_1)), i1.ɵdid(2, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "current-rating"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stars; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.rating$)); _ck(_v, 4, 0, currVal_1); }); }
export function View_StarRatingWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "star-rating-widget", [], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseleave" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).clearRating() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StarRatingWidgetComponent_0, RenderType_StarRatingWidgetComponent)), i1.ɵprd(5120, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i11.StarRatingWidgetComponent]), i1.ɵdid(2, 49152, null, 0, i11.StarRatingWidgetComponent, [], null, null)], null, null); }
var StarRatingWidgetComponentNgFactory = i1.ɵccf("star-rating-widget", i11.StarRatingWidgetComponent, View_StarRatingWidgetComponent_Host_0, {}, {}, []);
export { StarRatingWidgetComponentNgFactory as StarRatingWidgetComponentNgFactory };
