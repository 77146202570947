var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LoadRelatedTitles } from '../../state/title-actions';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
var RelatedTitlesPanelComponent = /** @class */ (function () {
    function RelatedTitlesPanelComponent(store, route) {
        this.store = store;
        this.route = route;
    }
    Object.defineProperty(RelatedTitlesPanelComponent.prototype, "noRelatedTitles", {
        // need at least 4 related titles to display properly
        get: function () {
            var length = this.store.selectSnapshot(TitleState.relatedTitles).length;
            return length < 4;
        },
        enumerable: true,
        configurable: true
    });
    RelatedTitlesPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function () {
            _this.store.dispatch(new LoadRelatedTitles());
        });
    };
    __decorate([
        Select(TitleState.relatedTitles),
        __metadata("design:type", Observable)
    ], RelatedTitlesPanelComponent.prototype, "related$", void 0);
    return RelatedTitlesPanelComponent;
}());
export { RelatedTitlesPanelComponent };
