var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UploadValidation } from './upload-validation';
var BlockedExtensionsValidation = /** @class */ (function (_super) {
    __extends(BlockedExtensionsValidation, _super);
    function BlockedExtensionsValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.i18n.t('These file types are not allowed: :extensions', { extensions: _this.params.extensions.join(', ') });
        return _this;
    }
    BlockedExtensionsValidation.prototype.fails = function (file) {
        return this.params.extensions.some(function (extension) {
            return extension === file.extension;
        });
    };
    return BlockedExtensionsValidation;
}(UploadValidation));
export { BlockedExtensionsValidation };
