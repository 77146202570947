var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { MEDIA_TYPE } from '../../../media-type';
import { TitleUrlsService } from '../../title-urls.service';
import { shareLinkSocially } from '../../../../../common/core/utils/share-link-socially';
import { Settings } from '../../../../../common/core/config/settings.service';
import { shareViaEmail } from '../../../../../common/core/utils/share-via-email';
import { copyToClipboard } from '../../../../../common/core/utils/copy-link-to-clipboard';
import { MESSAGES } from '../../../../toast-messages';
import { Translations } from '../../../../../common/core/translations/translations.service';
import { Toast } from '../../../../../common/core/ui/toast.service';
var TitlePrimaryDetailsPanelComponent = /** @class */ (function () {
    function TitlePrimaryDetailsPanelComponent(urls, settings, store, i18n, toast) {
        this.urls = urls;
        this.settings = settings;
        this.store = store;
        this.i18n = i18n;
        this.toast = toast;
    }
    TitlePrimaryDetailsPanelComponent.prototype.isEpisode = function () {
        return this.item.type === MEDIA_TYPE.EPISODE;
    };
    TitlePrimaryDetailsPanelComponent.prototype.shareUsing = function (type) {
        var title = this.store.selectSnapshot(TitleState.title);
        var link = this.settings.getBaseUrl(true) + 'titles/' + title.id;
        if (type === 'mail') {
            var siteName = this.settings.get('branding.site_name');
            var subject = this.i18n.t('Check out this link on ') + siteName;
            var body = title.name + " - " + siteName + " - " + link;
            shareViaEmail(subject, body);
        }
        else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        }
        else {
            shareLinkSocially(type, link, title.name);
        }
    };
    __decorate([
        Select(TitleState.title),
        __metadata("design:type", Observable)
    ], TitlePrimaryDetailsPanelComponent.prototype, "title$", void 0);
    return TitlePrimaryDetailsPanelComponent;
}());
export { TitlePrimaryDetailsPanelComponent };
