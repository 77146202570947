var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FormBuilder } from '@angular/forms';
import { LoadFilterOptions, LoadMoreTitles, ReloadTitles } from '../../state/browse/browse-title-actions';
import { BrowseTitleState } from '../../state/browse/browse-title.state';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSelectionList } from '@angular/material';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BreakpointsService } from '../../../../../common/core/ui/breakpoints.service';
import { InfiniteScroll } from '../../../../../common/core/ui/infinite-scroll/infinite.scroll';
import { objectsAreEqual } from '../../../../../common/core/utils/objects-are-equal';
var BrowseTitlesComponent = /** @class */ (function (_super) {
    __extends(BrowseTitlesComponent, _super);
    function BrowseTitlesComponent(store, fb, route, breakpoints) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.fb = fb;
        _this.route = route;
        _this.breakpoints = breakpoints;
        _this.form = _this.fb.group({
            type: [],
            genre: [],
            released: [],
            score: [],
            country: [],
            language: [],
            runtime: [],
            certification: [],
            order: [],
        });
        return _this;
    }
    BrowseTitlesComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.store.dispatch(new LoadFilterOptions());
        // reload titles when form is updated
        this.form.valueChanges
            .pipe(debounceTime(50), distinctUntilChanged(function (a, b) { return objectsAreEqual(a, b); }))
            .subscribe(function (value) {
            _this.store.dispatch(new ReloadTitles(value));
        });
        this.route.queryParams.subscribe(function (params) {
            _this.form.patchValue(params);
        });
        // patch form with initial filters from query params
        this.form.patchValue(this.store.selectSnapshot(BrowseTitleState.filters));
    };
    BrowseTitlesComponent.prototype.clearAllFilters = function () {
        this.form.reset();
    };
    BrowseTitlesComponent.prototype.loadMoreItems = function () {
        this.store.dispatch(new LoadMoreTitles());
    };
    BrowseTitlesComponent.prototype.canLoadMore = function () {
        return this.store.selectSnapshot(BrowseTitleState.canLoadMore);
    };
    BrowseTitlesComponent.prototype.isLoading = function () {
        return this.store.selectSnapshot(BrowseTitleState.loading);
    };
    __decorate([
        Select(BrowseTitleState.titles),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "titles$", void 0);
    __decorate([
        Select(BrowseTitleState.doesNotHaveResults),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "doesNotHaveResults$", void 0);
    __decorate([
        Select(BrowseTitleState.loading),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "loading$", void 0);
    __decorate([
        Select(BrowseTitleState.anyFilterActive),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "anyFilterActive$", void 0);
    __decorate([
        Select(BrowseTitleState.countries),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "countries$", void 0);
    __decorate([
        Select(BrowseTitleState.languages),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "languages$", void 0);
    __decorate([
        Select(BrowseTitleState.genres),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "genres$", void 0);
    __decorate([
        Select(BrowseTitleState.certifications),
        __metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "certifications$", void 0);
    return BrowseTitlesComponent;
}(InfiniteScroll));
export { BrowseTitlesComponent };
