export const TITLE_GENRE_OPTIONS = [
    'drama',
    'action',
    'thriller',
    'comedy',
    'science fiction',
    'horror',
    'mystery',
    'romance',
];
