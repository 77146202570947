var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { Settings } from '../../../../../common/core/config/settings.service';
var EpisodePageComponent = /** @class */ (function () {
    function EpisodePageComponent(store, settings) {
        this.store = store;
        this.settings = settings;
    }
    EpisodePageComponent.prototype.ngOnInit = function () {
        var _this = this;
        // TODO: remove settimout
        setTimeout(function () {
            _this.store.dispatch(new ToggleGlobalLoader(false));
        });
    };
    __decorate([
        Select(TitleState.episode),
        __metadata("design:type", Observable)
    ], EpisodePageComponent.prototype, "episode$", void 0);
    __decorate([
        Select(TitleState.backdrop),
        __metadata("design:type", Observable)
    ], EpisodePageComponent.prototype, "backdropImage$", void 0);
    return EpisodePageComponent;
}());
export { EpisodePageComponent };
