var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { HomepageService } from '../homepage.service';
import { ChangeSlide, ChangeSliderFocusState, LoadLists } from './homepage-state.actions';
var HomepageState = /** @class */ (function () {
    function HomepageState(homepage) {
        this.homepage = homepage;
    }
    HomepageState.sliderList = function (state) {
        return state.lists[0];
    };
    HomepageState.activeSlide = function (state) {
        return state.slider.activeSlide;
    };
    HomepageState.autoSlide = function (state) {
        return state.slider.autoSlide;
    };
    HomepageState.slideCount = function (state) {
        return state.slider.slideCount;
    };
    HomepageState.focused = function (state) {
        return state.slider.focused;
    };
    HomepageState.content = function (state) {
        return state.lists.slice(1);
    };
    HomepageState.prototype.loadLists = function (ctx) {
        if (ctx.getState().contentLoaded)
            return;
        return this.homepage.getLists().pipe(tap(function (response) {
            var sliderList = response.lists[0];
            ctx.patchState({
                lists: response.lists,
                contentLoaded: true,
                slider: __assign({}, ctx.getState().slider, { slideCount: sliderList ? sliderList.items.length : 0 }),
            });
        }));
    };
    HomepageState.prototype.changeSlide = function (ctx, action) {
        var lastSlide = ctx.getState().slider.slideCount - 1;
        var index = action.index;
        if (action.index > lastSlide) {
            index = 0;
        }
        else if (action.index < 0) {
            index = lastSlide;
        }
        return ctx.patchState({
            slider: __assign({}, ctx.getState().slider, { activeSlide: index })
        });
    };
    HomepageState.prototype.changeSliderFocusState = function (ctx, action) {
        return ctx.patchState({
            slider: __assign({}, ctx.getState().slider, { focused: action.state })
        });
    };
    __decorate([
        Action(LoadLists),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState.prototype, "loadLists", null);
    __decorate([
        Action(ChangeSlide),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ChangeSlide]),
        __metadata("design:returntype", void 0)
    ], HomepageState.prototype, "changeSlide", null);
    __decorate([
        Action(ChangeSliderFocusState),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ChangeSliderFocusState]),
        __metadata("design:returntype", void 0)
    ], HomepageState.prototype, "changeSliderFocusState", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState, "sliderList", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState, "activeSlide", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState, "autoSlide", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState, "slideCount", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState, "focused", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HomepageState, "content", null);
    HomepageState = __decorate([
        State({
            name: 'homepage',
            defaults: {
                slider: {
                    activeSlide: 0,
                    autoSlide: false,
                    slideCount: 0,
                    focused: false,
                },
                contentLoaded: false,
                lists: [],
            }
        }),
        __metadata("design:paramtypes", [HomepageService])
    ], HomepageState);
    return HomepageState;
}());
export { HomepageState };
