var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TitleService } from '../common/core/services/title.service';
import { MEDIA_TYPE } from './site/media-type';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Translations } from '../common/core/translations/translations.service';
import { Settings } from '../common/core/config/settings.service';
import { Store } from '@ngxs/store';
import { TitleState } from './site/titles/state/title-state';
import { PersonState } from './site/people/state/person-state';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../common/core/translations/translations.service";
import * as i4 from "../common/core/config/settings.service";
import * as i5 from "@ngxs/store";
var PageTitleService = /** @class */ (function (_super) {
    __extends(PageTitleService, _super);
    function PageTitleService(router, title, i18n, settings, store) {
        var _this = _super.call(this, router, title, i18n, settings) || this;
        _this.router = router;
        _this.title = title;
        _this.i18n = i18n;
        _this.settings = settings;
        _this.store = store;
        return _this;
    }
    PageTitleService.prototype.getTitle = function (data) {
        switch (data.name) {
            case MEDIA_TYPE.MOVIE:
            case MEDIA_TYPE.SERIES:
            case MEDIA_TYPE.SEASON:
            case MEDIA_TYPE.EPISODE:
                return this.getMovieTitle();
            case MEDIA_TYPE.PERSON:
                return this.getPersonTitle();
            case 'browse':
                return this.i18n.t('Browse');
            case 'people':
                return this.i18n.t('Popular People');
            case 'news':
                return this.i18n.t('Latest News');
            case 'userLists':
                return this.i18n.t('User Lists');
            case 'watchlist':
                return this.i18n.t('Your Watchlist');
            case 'homepage':
                return this.i18n.t('MTDb - Movies, TV and Celebrities');
            default:
                return _super.prototype.getTitle.call(this, data);
        }
    };
    PageTitleService.prototype.getMovieTitle = function () {
        var title = this.store.selectSnapshot(TitleState.title);
        return title.name + ' (' + title.year + ')';
    };
    PageTitleService.prototype.getPersonTitle = function () {
        var person = this.store.selectSnapshot(PersonState.person);
        return person.name;
    };
    PageTitleService.ngInjectableDef = i0.defineInjectable({ factory: function PageTitleService_Factory() { return new PageTitleService(i0.inject(i1.Router), i0.inject(i2.Title), i0.inject(i3.Translations), i0.inject(i4.Settings), i0.inject(i5.Store)); }, token: PageTitleService, providedIn: "root" });
    return PageTitleService;
}(TitleService));
export { PageTitleService };
