/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./genre-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./genre-widget.component";
var styles_GenreWidgetComponent = [i0.styles];
var RenderType_GenreWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GenreWidgetComponent, data: {} });
export { RenderType_GenreWidgetComponent as RenderType_GenreWidgetComponent };
function View_GenreWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"]))], null, null); }
function View_GenreWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "genre"], ["draggable", "false"], ["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), i1.ɵdid(3, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(4, { genre: 0 }), i1.ɵpad(5, 1), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenreWidgetComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, _v.context.$implicit.name); var currVal_3 = _ck(_v, 5, 0, "/browse"); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = !_v.context.last; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = (_v.context.$implicit.display_name || _v.context.$implicit.name); _ck(_v, 6, 0, currVal_4); }); }
export function View_GenreWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_GenreWidgetComponent_1)), i1.ɵdid(1, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i6.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.genres, 0, _co.limit)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GenreWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-widget", [], null, null, null, View_GenreWidgetComponent_0, RenderType_GenreWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i7.GenreWidgetComponent, [], null, null)], null, null); }
var GenreWidgetComponentNgFactory = i1.ɵccf("genre-widget", i7.GenreWidgetComponent, View_GenreWidgetComponent_Host_0, { genres: "genres", limit: "limit" }, {}, []);
export { GenreWidgetComponentNgFactory as GenreWidgetComponentNgFactory };
