var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { ToggleGlobalLoader } from './app-state-actions';
var AppState = /** @class */ (function () {
    function AppState() {
    }
    AppState.loading = function (state) {
        return state.loading;
    };
    AppState.prototype.toggleGlobalLoader = function (ctx, action) {
        ctx.patchState({ loading: action.visible });
    };
    __decorate([
        Action(ToggleGlobalLoader),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ToggleGlobalLoader]),
        __metadata("design:returntype", void 0)
    ], AppState.prototype, "toggleGlobalLoader", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AppState, "loading", null);
    AppState = __decorate([
        State({
            name: 'app',
            defaults: {
                loading: false,
            }
        })
    ], AppState);
    return AppState;
}());
export { AppState };
