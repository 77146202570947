var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { CustomHomepage } from '../common/core/pages/custom-homepage.service';
import { Settings } from '../common/core/config/settings.service';
import { AppHttpClient } from '../common/core/http/app-http-client.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AppState } from './state/app-state';
import { Observable } from 'rxjs';
import { PageTitleService } from './page-title.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(customHomepage, settings, httpClient, router, pageTitle) {
        this.customHomepage = customHomepage;
        this.settings = settings;
        this.httpClient = httpClient;
        this.router = router;
        this.pageTitle = pageTitle;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.customHomepage.select();
        this.pageTitle.init();
        this.settings.setHttpClient(this.httpClient);
        // google analytics
        if (this.settings.get('analytics.tracking_code')) {
            this.triggerAnalyticsPageView();
        }
    };
    AppComponent.prototype.triggerAnalyticsPageView = function () {
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }))
            .subscribe(function (event) {
            if (!window['ga'])
                return;
            window['ga']('set', 'page', event.urlAfterRedirects);
            window['ga']('send', 'pageview');
        });
    };
    __decorate([
        Select(AppState.loading),
        __metadata("design:type", Observable)
    ], AppComponent.prototype, "loading$", void 0);
    return AppComponent;
}());
export { AppComponent };
