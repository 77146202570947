import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var ListsService = /** @class */ (function () {
    function ListsService(http) {
        this.http = http;
    }
    ListsService.prototype.get = function (id, params) {
        return this.http.get('lists/' + id, params);
    };
    ListsService.prototype.getAll = function (params) {
        return this.http.get('lists', params);
    };
    ListsService.prototype.update = function (id, payload) {
        return this.http.put('lists/' + id, payload);
    };
    ListsService.prototype.create = function (payload) {
        return this.http.post('lists', payload);
    };
    ListsService.prototype.addItem = function (listId, mediaItem) {
        return this.http.post('lists/' + listId + '/add', { itemId: mediaItem.id, itemType: mediaItem.type });
    };
    ListsService.prototype.removeItem = function (listId, mediaItem) {
        return this.http.post('lists/' + listId + '/remove', { itemId: mediaItem.id, itemType: mediaItem.type });
    };
    ListsService.prototype.reorder = function (listId, itemIds) {
        return this.http.post('lists/' + listId + '/reorder', { itemIds: itemIds });
    };
    ListsService.prototype.delete = function (listId) {
        return this.http.delete('lists/' + listId);
    };
    ListsService.ngInjectableDef = i0.defineInjectable({ factory: function ListsService_Factory() { return new ListsService(i0.inject(i1.AppHttpClient)); }, token: ListsService, providedIn: "root" });
    return ListsService;
}());
export { ListsService };
